import React, { Fragment } from 'react';
import get from 'lodash.get';
import { useStaticQuery, graphql } from 'gatsby';

import { getProductUrl } from 'helpers/url';
import { useIntl } from 'gatsby-plugin-react-intl';
import CustomLink from 'components/Link';

import {
    SharedStyles,
    Container,
    Slider,
    SliderFavorite3,
    ProductItem,
} from '../kenra-storybook/index';

const { StSectionTitle, StSectionMobileViewMore, StLink } = SharedStyles;
const { StSliderFavorite3 } = SliderFavorite3;

export default function ProductSlider(props) {
    let {
        productHandles,
        collectionHandle,
        title,
        bigTitle,
        viewAllLink,
        isOnBlog
    } = props;

    let intl = useIntl();
    const {
        allShopifyCollection,
        allContentfulProduct,
    } = useStaticQuery(graphql`
        query {
            allShopifyCollection {
                nodes {
                    ...shopifyCollectionFields
                }
            }
            allContentfulProduct {
                nodes {
                    id
                    title
                    description
                    shopifyProduct {
                        handle
                    }
                    productItem {
                        title
                        images {
                            id
                            localFile {
                                publicURL
                                thumbnail: childImageSharp {
                                    resize(width: 100, height: 100) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    if (collectionHandle) {
        let collection = allShopifyCollection.nodes.find(
            c => c.handle === collectionHandle
        );
        if (!collection) {
            console.error('ProductSlider: collection not found');
            return null;
        }

        productHandles = collection.products.map(p => p.handle);
    }
    if (!Array.isArray(productHandles) || productHandles.length === 0)
        return null;

    let products = productHandles.map(handle => {
        let contentfulProduct = allContentfulProduct.nodes.find(
            p => p.shopifyProduct.handle === handle
        );

        return {
            handle,
            contentfulProduct,
        };
    });

    let Wrapper = bigTitle ? StSectionMobileViewMore : Fragment;

    return (
        <Wrapper>
            {title && bigTitle ? (
                <StSectionTitle>
                    <Container>
                        <h2>{title}</h2>
                        {viewAllLink && (
                            <StLink mobileStyleLinkMore>
                                <CustomLink
                                    href={viewAllLink}
                                >{`${intl.formatMessage({
                                    id: 'view-all-text',
                                })} (${products.length})`}</CustomLink>
                            </StLink>
                        )}
                    </Container>
                </StSectionTitle>
            ) : (
                <Container>
                    <StSectionTitle alignLeft>
                        <h2>{title}</h2>
                    </StSectionTitle>
                </Container>
            )}
            {isOnBlog && 
            <Container>
                <StSectionTitle>
                    <h2>{intl.formatMessage({
                        id: 'products-used'
                    })}</h2>
                </StSectionTitle>
            </Container>
            }
            <Slider settings={{ slidesPerView: 'auto' }}>
                {products.map((product, index) => {
                    let { contentfulProduct, handle } = product;
                    if (!contentfulProduct) {
                        return <></>;
                    }
                    return (
                        <StSliderFavorite3 key={`${product.id}_${index}`}>
                            
                            <ProductItem
                                link={getProductUrl(handle)}
                                img={get(
                                    contentfulProduct,
                                    'productItem.images.0.localFile.publicURL'
                                )}
                                title={get(
                                    contentfulProduct,
                                    'productItem.title'
                                )}
                                text={get(contentfulProduct, 'description')}
                            />
                        </StSliderFavorite3>
                    );
                })}
            </Slider>
        </Wrapper>
    );
}
