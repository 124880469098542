import React from 'react';
import get from 'lodash.get';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import ReactMarkdown from 'react-markdown';

import ProductSlider from 'blocks/ProductSlider';
import { useIntl } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    Slider,
    SliderColorMelt,
    SliderFavorite3,
    SharedStyles,
    Container,
    VideoWithInfo,
    ProductItem,
    ListOfVideo,
} from '../kenra-storybook/index';

import { getProductUrl, getVideoUrl } from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { multiline } from 'helpers/text';

const { StSliderColorMelt } = SliderColorMelt;
const { StSliderFavorite3 } = SliderFavorite3;

const {
    StSectionInfo,
    StTitle,
    StSectionMobileViewMore,
    StSectionTitle,
    StLink,
} = SharedStyles;

export default function Video(props) {
    let intl = useIntl();
    let {
        video: {
            youtubeLink,
            title,
            showEvent,
            eventTitle,
            eventText,
            stepsTitle,
            usedProducts,
            otherVideos,
        },
    } = props;

    let youTubeId = getYouTubeId(youtubeLink);
    return (
        <>
            <Spacing removeSpaceBottom>
                <StSectionInfo removeMobileBg desktopMarginSmall>
                    <Container>
                        <StTitle colorBlack>
                            <h1>{multiline(title.title)}</h1>
                        </StTitle>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <VideoWithInfo
                    useImgOverlay
                    img={getPreviewUrl(youTubeId)}
                    channel="youtube"
                    videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                    text={
                        showEvent &&
                        `
              <h3>${eventTitle}</h3>
              
              ${eventText && documentToHtmlString(JSON.parse(eventText.raw || '{}'))}
            `
                    }
                    href="/"
                    buttonText={intl.formatMessage({
                        id: 'video-button-book-class',
                    })}
                />
            </Spacing>

            {/* STEPS */}
            {/* TODO: Restore newSteps */}

            {/* PRODUCTS USED */}

            {Array.isArray(usedProducts) && usedProducts.length > 0 && (
                <Spacing>
                    <ProductSlider
                        title={intl.formatMessage({
                            id: 'product-products-used',
                        })}
                        productHandles={usedProducts.map(
                            p => p.shopifyProduct.handle
                        )}
                    />
                </Spacing>
            )}

            {/* OTHER VIDEOS */}

            {Array.isArray(otherVideos) && otherVideos.length > 0 && (
                <Spacing>
                    <Container>
                        <StSectionTitle alignLeft>
                            <h3>
                                {intl.formatMessage({
                                    id: 'product-you-may-also-like',
                                })}
                            </h3>
                        </StSectionTitle>
                    </Container>

                    <ListOfVideo
                        mobileScrollable
                        items={otherVideos.map(video => {
                            let { youtubeLink, title, slug, type } = video;
                            let youTubeId = getYouTubeId(youtubeLink);

                            return {
                                img: getPreviewUrl(youTubeId),
                                title: multiline(title.title),
                                subtitle: type,
                                href: getVideoUrl(slug),
                            };
                        })}
                    />
                </Spacing>
            )}
        </>
    );
}
