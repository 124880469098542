import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Video from 'components/Video';
import SEO from 'components/seo';
import get from 'lodash.get';

export default props => {
    let { contentfulVideo } = props.data;
    if (!contentfulVideo) return 'No video';

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulVideo,
                    'seoListing.description.description'
                )}
                title={get(contentfulVideo, 'seoListing.seoTitle')}
                slug={contentfulVideo.slug}
            />
            <Video video={contentfulVideo} />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!, $locale: String) {
        contentfulVideo(slug: { eq: $slug }, node_locale: { eq: $locale }) {
            ...ContentfulVideoFields
        }
    }
`;
